
import Vue from 'vue';
import * as SurveyVue from 'survey-vue';
import { Submitter } from '../services/submitter';
import { SurveyService } from '@/services/survey';
import Toolbar from './Toolbar.vue';
import { FormType } from '../models/survey';
const Survey = SurveyVue.Survey as any;
export default Vue.extend({
  props: {
    surveyType: String,
    language: String,
  },
  components: {
    Survey,
    Toolbar,
  },
  data() {
    return {
      survey: (this as any).getSurvey(),
      finished: false,
      currentPage: 0,
    };
  },
  methods: {
    getSurvey(): SurveyVue.SurveyModel {
      const surveyStructure = this.$store.getters.surveyStructure(
        this.surveyType
      );
      const survey = new SurveyVue.Model(surveyStructure);
      survey.onComplete.add(this.onComplete);
      survey.onCurrentPageChanged.add(this.onPageChanged);
      if (this.language) {
        survey.locale = this.language;
      }
      setTheme();
      return survey;
    },
    async onComplete(survey: SurveyVue.SurveyModel, options: any) {
      this.submit(survey.data);
      this.finished = true;
      setTimeout(() => this.$router.push({ path: '/home' }), 3000);
    },
    async submit(survey: SurveyVue.SurveyModel) {
      const shelter: string | undefined = this.$route.query.shelter as any;
      const wrapped = await SurveyService.wrapSurvey(
        survey as any,
        this.surveyType as FormType,
        shelter
      );
      this.$store.commit('addSurvey', wrapped);
      await Submitter.trySubmitUnsubmitted(true);
    },
    async onPageChanged(survey: SurveyVue.SurveyModel, options: any) {
      this.currentPage = survey.currentPageNo;
      this.survey = survey;
      // Scroll to top of page. Required for safari.
      scroll(0, 0);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.finished) {
      next();
    } else if (this.currentPage == 0) {
      next();
    } else {
      this.survey.prevPage();
      next(false);
    }
  },
});

function setTheme() {
  SurveyVue.StylesManager.applyTheme('orange');
}
